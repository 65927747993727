/* @font-face {
  font-family: 'Aller Display';
  src: local('Aller Display'),
    url('./fonts/AllerDisplay/AllerDisplay.woff2') format('woff2'),
    url('./fonts/AllerDisplay/AllerDisplay.woff') format('woff'),
    url('./fonts/AllerDisplay/AllerDisplay.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
} */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-phone-number-input {
  margin-top: 16px;
  position: relative;
  z-index: 1;
}

input.react-phone-number-input__input.react-phone-number-input__phone {
  /* height: 1.1875em; */
  padding-left: 12px;
  border-radius: 10px;
  height: 42px;
  border: 1px solid #E3E3E3;
  width: 243px;
  color: #00003D;
}

input.react-phone-number-input__input.react-phone-number-input__phone::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  opacity: 1;
  content:"Mobile";
}

input.react-phone-number-input__input.react-phone-number-input__phone::-moz-placeholder {
  /* Firefox 19+ */
  opacity: 1;
  content:"Mobile";
}

input.react-phone-number-input__input.react-phone-number-input__phone:-ms-input-placeholder {
  /* IE 10+ */
  opacity: 1;
  content:"Mobile";
}

input.react-phone-number-input__input.react-phone-number-input__phone:-moz-placeholder {
  /* Firefox 18- */
  opacity: 1;
  content:"Mobile";
}

.react-phone-number-input__country--native {
  width: 46px;
  justify-content: space-between;
  color: #00003D;
}

.react-phone-number-input__country img {
  width: 35px;
}

.react-phone-number-input__icon {
  border: none !important;
}



